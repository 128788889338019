<template>
	<div>
		<!-- 登录信息 -->
		<global-tips></global-tips>
		<el-card>
			<!-- 搜索框 -->
			 <el-form v-show="showSearch" ref="queryForm" :model="queryForm" inline size="small">
				<el-form-item label="名称" label-width="50px" prop="keyword">
				  <el-input v-model="queryForm.keyword" placeholder="请输入名称" type="text" clearable style="width: 230px" />
				</el-form-item>
				<el-form-item>
				  <el-button type="primary" icon="el-icon-search" size="mini" @click="query">查询</el-button>
				  <el-button icon="el-icon-refresh" size="mini" @click="resetQuery('queryForm')">重置</el-button>
				</el-form-item>
			</el-form>
			<!--自定义刷新组件-->
			<el-row :gutter="10" class="mb-1">
				<el-button type="primary" icon="el-icon-plus" size="mini" @click="handleAdd">新增</el-button>
				<right-toolbar :show-search.sync="showSearch" @queryTable="query" />
			</el-row>
			<!--表格-->
			 <el-table
				v-loading="loading"
				size="small"
				:data="list"
				element-loading-text="Loading"
				highlight-current-row
				style="width: 100%"
			 >
				<el-table-column label="序号" width="80" align="center">
				  <template slot-scope="scope">
					{{ (queryForm.page - 1) * queryForm.limit + scope.$index + 1 }}
				  </template>
				</el-table-column>
				<el-table-column label="物流公司名称" align="center">
				  <template slot-scope="scope">
					<span>{{ scope.row.logi_name }}</span>
				  </template>
				</el-table-column>
				<el-table-column label="物流公司编码" align="center">
				  <template slot-scope="scope">
					<span>{{ scope.row.logi_code }}</span>
				  </template>
				</el-table-column>
				<el-table-column label="排序" align="center">
					<template slot-scope="{row, $index}">
					  <div @click.stop="{{changeState($index,'sort','editSortInput')}}">
					  <el-input
						v-if="editable[$index]"
						v-model="row.sort"
						size="mini"
						class="editSortInput"
					  ></el-input>
					  <span v-else>{{row.sort}}</span>
					  </div>
					</template>
				</el-table-column>
				<el-table-column label="操作" min-width="100" align="center">
				  <template slot-scope="scope">
					<el-tooltip class="item" effect="dark" content="编辑" placement="top">
					  <el-button type="success" icon="el-icon-edit" size="mini" circle @click="handleEdit(scope.row)"></el-button>		
		            </el-tooltip>
					<el-tooltip class="item" effect="dark" content="删除" placement="top">
					  <el-button type="danger" icon="el-icon-delete" size="mini" circle @click="handleDelete(scope.row.id)"></el-button>		
					</el-tooltip>
				  </template>
				</el-table-column>
			 </el-table>
			 <pagination
				:total="total"
				auto-scroll
				:page.sync="queryForm.page"
				:limit.sync="queryForm.limit"
				@pagination="query"
			 />
		</el-card>
		<!-- 编辑-->
		<el-dialog :visible.sync="open" width="600px" :close-on-click-modal="false" append-to-body :title="title">
		  <el-form ref="form" :model="form" :rules="rules" label-width="120px" size="small">
		    <el-form-item label="物流公司名称" prop="logi_name">
		      <el-input v-model="form.logi_name" placeholder="请输入物流公司名称" type="text" clearable />
		    </el-form-item>
		    <el-form-item label="物流公司编码" prop="logi_code">
		      <el-input v-model="form.logi_code" placeholder="请输入物流公司编码" type="text" clearable />
		    </el-form-item>
			<el-form-item label="排序" prop="sort">
			  <el-input v-model="form.sort" placeholder="请输入物流排序" type="number" clearable />
			</el-form-item>
		  </el-form>
		  <div slot="footer" class="dialog-footer">
		    <el-button size="mini" @click="cancel">取 消</el-button>
		    <el-button type="primary" size="mini" @click="handleSubmit">确 定</el-button>
		  </div>
		</el-dialog>
	</div>
</template>

<script>
	import GlobalTips from "@/components/GlobalTips";
	import common from "@/common/mixins/common.js"
	export default {
		inject:['app'],
		mixins:[common],
		components: {
			GlobalTips,
		},
		data() {
			return {
				preUrl: 'logistics',
				queryForm: {
					page: 1,
					limit: 10,
					keyword: '',
				},
				form:{
					logi_name:'',
					logi_code:'',
					sort:100,
				},
				rules:{
					logi_name: [
					  { required: true, message: '请输入物流公司名称', trigger: 'blur' }
					],
					logi_code: [
					  { required: true, message: '请输入物流公司编码', trigger: 'blur' }
					],
				},
			}
		},
		mounted() {
			
		},
		methods:{
			reset() {
			  this.form = {
			   logi_name:'',
			   logi_code:'',
			   sort:100,
			  }
			  this.resetForm('form')
			},
		}
	}
</script>

<style>
</style>